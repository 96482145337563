<template lang="pug">
    section#inscreva-se
        img(src="@images/objetos/map.png").map
        img(src="@images/objetos/case.png").case
        img(src="@images/objetos/pen.png").pen
        img(src="@images/objetos/pin.png").pin-1
        img(src="@images/objetos/pin.png").pin-2
        .content
            h2 Inscreva-se
            .text
                //- p Você terá a oportunidade de fazer um test drive nos novos Land Rover Defender e Discovery.
                p O Land Rover Experience é um encontro exclusivo para clientes Euro Import e seu Land Rover.
            form(@submit.prevent="send")
                .page(v-show="page == 1")
                    p.text Seus dados
                    .input
                        label(for="input_nome") Nome:
                        input(name="input_nome", id="input_nome", type="text", v-model="formulario.nome")
                        span(v-if="errors.nome") {{ errors.nome }}
                    .input
                        label(for="input_cpf") CPF:
                        input(name="input_cpf", id="input_cpf", type="tel", v-model="formulario.cpf", v-mask="['###.###.###-##']")
                        span(v-if="errors.cpf") {{ errors.cpf }}
                    .input
                        label(for="input_email") E-mail:
                        input(name="input_email", id="input_email", type="email", v-model="formulario.email")
                        span(v-if="errors.email") {{ errors.email }}
                    .input
                        label(for="input_celular") Celular:
                        input(name="input_celular", id="input_celular", type="tel", v-model="formulario.celular", v-mask="['(##) #####-####']")
                        span(v-if="errors.celular") {{ errors.celular }}
                    .submit
                        button(type="button", @click="page++") Próximo
                .page(v-show="page == 2")
                    p.text Dados do acompanhante
                    .input
                        label(for="input_nome_acompanhante") Nome:
                        input(name="input_nome_acompanhante", id="input_nome_acompanhante", type="text", v-model="formulario.nome_acompanhante")
                        span(v-if="errors.nome_acompanhante") {{ errors.nome_acompanhante }}
                    .input
                        label(for="input_cpf_acompanhante") CPF:
                        input(name="input_cpf_acompanhante", id="input_cpf_acompanhante", type="tel", v-model="formulario.cpf_acompanhante", v-mask="['###.###.###-##']")
                        span(v-if="errors.cpf_acompanhante") {{ errors.cpf_acompanhante }}
                    .input
                        label(for="input_email_acompanhante") E-mail:
                        input(name="input_email_acompanhante", id="input_email_acompanhante", type="email_acompanhante", v-model="formulario.email_acompanhante")
                        span(v-if="errors.email_acompanhante") {{ errors.email_acompanhante }}
                    .input
                        label(for="input_celular_acompanhante") Celular:
                        input(name="input_celular_acompanhante", id="input_celular_acompanhante", type="tel", v-model="formulario.celular_acompanhante", v-mask="['(##) #####-####']")
                        span(v-if="errors.celular_acompanhante") {{ errors.celular_acompanhante }}
                    .submit.double
                        button(type="button", @click="page--") Anterior
                        button(type="button", @click="page++") Próximo
                .page(v-show="page == 3")
                    p.text Dados do veículo
                    .input
                        label(for="input_modelo") Modelo:
                        input(name="input_modelo", id="input_modelo", type="text", v-model="formulario.modelo")
                        span(v-if="errors.modelo") {{ errors.modelo }}
                    .input
                        label(for="input_ano") Ano:
                        input(name="input_ano", id="input_ano", type="tel", v-model="formulario.ano", v-mask="['####']")
                        span(v-if="errors.ano") {{ errors.ano }}
                    .input
                        label(for="input_placa") Placa:
                        input(name="input_placa", id="input_placa", type="text", v-model="formulario.placa", v-mask="['SSS-#X##']")
                        span(v-if="errors.placa") {{ errors.placa }}
                    .checkbox
                        label(for="input_aceite")
                            input(name="input_aceite", id="input_aceite", type="checkbox", v-model="formulario.aceite")
                            i.check
                            | Aceito ser contatado.
                    .submit(:class="{ double: !disabled }")
                        button(type="button", v-if="!disabled", @click="page--") Anterior
                        button(type="submit", :disabled="disabled") {{ text }}
                transition(name="fade")
                    .success(v-if="success")
                        h2 Confirmação enviada.
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
    name: "section-inscreva-se",
    directives: {
        mask
    },
    data() {
        return {
            disabled: false,
            success: false,
            text: 'Inscreva-se',
            page: 1,
            formulario: {
                nome: '',
                cpf: '',
                email: '',
                celular: '',
                nome_acompanhante: '',
                cpf_acompanhante: '',
                email_acompanhante: '',
                celular_acompanhante: '',
                modelo: '',
                ano: '',
                placa: '',
                aceite: false,
            },
            errors: {}
        }
    },
    mounted() {
        setTimeout(() => {
            const scenes = []
            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#inscreva-se',
                triggerHook: 0.7,
                duration: 500
            }).setTween('#background .bg-experience', { 
                css: { 
                    transform: `translateX(-100%)`,
                    opacity: 0
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#inscreva-se',
                triggerHook: 0.5,
                duration: 500
            }).setTween('#background .bg-inscrevase', { 
                css: { 
                    transform: `translateX(0)`,
                    opacity: window.innerWidth > 1024 ? 1 : .5
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#inscreva-se',
                triggerHook: 0.4,
                duration: 300
            }).setTween('#inscreva-se .map', { 
                css: { 
                    transform: `translateY(0)`,
                    opacity: 1
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#inscreva-se',
                triggerHook: 0.3,
                duration: 300
            }).setTween('#inscreva-se .case', { 
                css: { 
                    transform: `translateY(0)`,
                    opacity: 1
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#inscreva-se',
                triggerHook: 0.3,
                duration: 300
            }).setTween('#inscreva-se .pen', { 
                css: { 
                    transform: window.innerWidth > 1024 ? `translateY(0)` : `translateY(0) scale(-1, 1)`,
                    opacity: 1
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#inscreva-se',
                triggerHook: 0.2,
                duration: 300
            }).setTween('#inscreva-se .pin-1', { 
                css: { 
                    transform: `translateY(0) scale(-1, 1)`,
                    opacity: 1
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#inscreva-se',
                triggerHook: 0.2,
                duration: 300
            }).setTween('#inscreva-se .pin-2', { 
                css: { 
                    transform: `translateY(0)`,
                    opacity: 1
                }
            }))

            scenes.forEach(scene => this.$scrollmagic.addScene(scene))
        }, 200)
    },
    methods: {
        send() {
            const formData = new FormData()
            Object.keys(this.formulario).forEach(key => formData.append(key, this.formulario[key]))

            this.disabled = true
            this.text = "Enviando..."
            this.$axios
                .post(`send_mail.php`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                // eslint-disable-next-line
                .then(response => {
                    this.disabled = false
                    this.text = "Enviado..."
                    this.success = true
                    this.formulario = {
                        nome: '',
                        cpf: '',
                        email: '',
                        celular: '',
                        nome_acompanhante: '',
                        cpf_acompanhante: '',
                        email_acompanhante: '',
                        celular_acompanhante: '',
                        modelo: '',
                        ano: '',
                        placa: '',
                    }
                })
                .catch(error => {
                    this.text = "Ocorreu um erro"
                    this.page = 1
                    this.errors = error.response.data.errors
                })
                .finally(() => {
                    setTimeout(() => {
                        this.page = 1
                        this.disabled = false
                        this.success = false
                        this.text = "Inscreva-se"
                    }, 3000)
                })
        }
    },
}
</script>

<style lang="stylus" scoped src="./InscrevaSe.styl"></style>